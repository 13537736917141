<template>
    <footer class="footer">
        <div class="container footer-area" id="footer">
            <div class="footer-content grid--footer-content">
                <!-- 微信公众号 -->
                <div class="social-media-col">
                    <p class="social-media-title">关注微信公众号获知CSSAT动态</p>
                    <div class="social-media-content">
                        <div class="social-media-code">
                            <img
                                    src="../../assets/img/footer/qrcode-service.jpg"
                                    alt="官方微信服务号二维码"
                            />
                            <span>CSSAT微信服务号</span>
                        </div>
                        <div class="social-media-code">
                            <img
                                    src="../../assets/img/footer/qrcode-reading.jpg"
                                    alt="官方微信订阅号二维码"
                            />
                            <span>CSSAT微信订阅号</span>
                        </div>
                    </div>
                </div>

                <!-- 联系我们 -->
                <div class="contacts-col">
                    <p class="footer-nav-title">联系我们</p>
                    <address class="contacts-content">
                        <p class="tel-title">
                            <i class="ph ph-phone-list contacts-icon"></i>
                            <span>电话</span>
                        </p>
                        <div class="tels">
                            <a class="tel-number" href="tel:010-56218127">010 - 56218127</a>
                            <a class="tel-number" href="tel:010-56218263">010 - 56218263</a>
                            <a class="tel-number" href="tel:010-56218232">010 - 56218232</a>
                            <a class="tel-number">9:00-18:00，周一到周五，节假日除外</a>
                        </div>
                        <p class="email-title">
                            <i class="ph ph-envelope contacts-icon"></i>
                            <span>邮箱</span>
                        </p>
                        <a class="email-address" href="mailto:service@cssat.cn"
                        >service@cssat.cn</a
                        >
                    </address>
                </div>

                <!-- 服务介绍 -->
                <div class="service-nav-col nav-col">
                    <p class="footer-nav-title">考试介绍</p>
                    <ul class="footer-nav">
                        <li>
                            <a class="footer-link" href="/">CSSAT介绍</a>
                        </li>
                        <li>
                            <router-link to="" class="footer-link"
                                         @click.native="openUrl('https://mp.weixin.qq.com/s/iVbsuq7_uWMQrBKav8mfGg')"
                            >CACA介绍</router-link>
                        </li>
                        <li>
                            <router-link to="" class="footer-link"
                                         @click.native="openUrl('https://mp.weixin.qq.com/s/lZhiO79mPQOGnab-cYJt-w')"
                            >CACA-Pre介绍</router-link>
                        </li>
                        <li>
                            <router-link to="" class="footer-link"
                                         @click.native="openUrl('https://mp.weixin.qq.com/s/5mMWQuQoYJepG9dK8xtwZg')"
                            >CICA介绍</router-link>
                        </li>
                        <li>
                            <router-link to="/tda" class="footer-link">TDA介绍</router-link>
                        </li>
                        <!--<li>
                            <router-link to="/tda#section-intro" class="footer-link">TDA特点</router-link>
                        </li>-->
                        <li>
                            <router-link to="/tda#register-subsection" class="footer-link">TDA申请</router-link>
                        </li>
                    </ul>
                </div>

                <!-- 服务入口 -->
                <div class="entrance-nav-col nav-col">
                    <p class="footer-nav-title">服务入口</p>
                    <ul class="footer-nav">
                        <li>
                            <a class="footer-link" href="https://school.cssat.cn">中学服务入口</a>
                        </li>
                        <li>
                            <a class="footer-link" href="https://student.cssat.cn">学生服务入口</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <p class="copyright">
            Copyright &copy;
            <span class="year">2024</span> 北京百世百诚教育科技有限公司 &nbsp; 版权所有 &nbsp;
            <a target="_blank" href="https://beian.miit.gov.cn">京ICP备19058825号-10</a> &nbsp; 京公网安备11010802034612号
        </p>
    </footer>
</template>

<script>
    export default {
        name: 'Footer',
        methods: {
            openUrl(url) {
                window.location.href = url;
            },
        }
    }
</script>
